<template>
  <div class="mt-2">
    <h3 class="mb-1">
      Co mówią inni? <br />
      Zobacz opinie z Google:
    </h3>
    <div class="rounded p-2 bg-white mb-1">
      <div class="mb-1 mt-1">
        <svg v-for="star in Math.ceil(reviews[0].average_rating)" xmlns="http://www.w3.org/2000/svg" width="20"
             height="20" fill="gold" class="bi bi-star-fill"
             viewBox="0 0 16 16">
          <path
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
      </div>
      <h3><b>DOSKONAŁA</b></h3>
      <p>Na podstawie <b>{{ reviews[0].number_of_ratings }} opinii.</b></p>
      <a href="https://g.page/r/CQhsgqSoAMEZEAI/review" target="_blank" class="btn btn-primary mb-1">Wszystkie opinie Google</a>
      <p>Więcej opinii na IG: <a href="https://www.instagram.com/chem.master.maturalny/" target="_blank">@chem.master.maturalny</a></p>
    </div>
    <div v-for="review in reviews" :key="review.id"
         class="border bg-white p-1 border-light rounded mb-1 d-flex flex-column justify-content-center">
      <div class="d-flex" style="height:80px">
        <div class="d-flex justify-content-start align-items-center w-75">
          <div class="px-1 flex justify-content-center align-items-center w-50">
            <img :src="review.profile_photo_url" alt="reviewer photo" style="width:50px;">
          </div>
          <div class="d-flex flex-column justify-content-center mt-2 w-50 align-items-start">
            <p><b>{{ review.author_name }}</b></p>
            <p class="text-gray">{{ review.relative_time_description }}</p>
          </div>
        </div>
        <div class="d-flex justify-content-center align-items-center w-25">
          <a target="_blank" :href="review.author_url">
            <img
                style="height: 25px"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png"
                alt="">
          </a>
        </div>
      </div>
      <div class="mb-1">
        <svg v-for="rating in Math.ceil(review.rating)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="gold"
             class="bi bi-star-fill"
             viewBox="0 0 16 16">
          <path
              d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
        </svg>
      </div>
      <div class="">
        <p class="">{{ review.text }}</p>
      </div>
    </div>
    <div class="p-1">
      <small>Publikacji podlega 5 opinii z platformy Google Moja Firma wystawione dla firmy ChemMaster, niezależnie od tego czy jest pozytywna czy negatywna. Średnia opinii jest wyliczona na podstawie wszystkich zebranych opinii. Opinie nie są sponsorowane i nie są weryfikowane czy pochodzą od osób, które zakupiły produkt.</small>
    </div>
  </div>
</template>

<script>
import {BCard} from "bootstrap-vue";

export default {
  name: "Reviews",
  components: {
    BCard,
  },
  data() {
    return {
      reviews: [],
    }
  },
  created() {
    this.$http.get('/api/reviews')
        .then(response => {
          this.reviews = response.data.reviews;
        })
  }
}
</script>

<style scoped>

</style>
