<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Opcje płatności</b-card-title>
        <b-card-text class="text-muted mt-25">
          Wybierz odpowiednią opcję
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <h6 class="card-holder-name my-75">
          Karta kredytowa
        </h6>

        <!-- Radios -->
        <b-form-group>
          <b-form-radio
            name="payment-method"
            value="john-doe-debit-card"
            checked="john-doe-debit-card"
          >
            PL **** **** **** XXXX
          </b-form-radio>

          <hr class="mb-2 mt-1">

          <p class="cursor-pointer">
            <feather-icon
              icon="PlusCircleIcon"
              size="21"
              class="mr-75"
            />
            <span class="align-middle">Dodaj kupon rabatowy</span>
          </p>
        </b-form-group>
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Detale płatności">

        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Cena
            </div>
            <div class="detail-amt">
              <strong>{{ getTotalCartSum() }} zł</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Dostawa
            </div>
            <div class="detail-amt discount-amt text-success">
              Darmowa
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Do zapłaty
            </div>
            <div class="detail-amt font-weight-bolder">
              {{ getTotalCartSum() }} zł
            </div>
          </li>
        </ul>

      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BFormGroup, BFormRadio, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
  },
  props: {
    paymentDetails: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.getCartProducts()
  },
  methods: {
    getCartProducts() {
      this.products = this.$store.getters['app/getCartItems']
    },
    getTotalCartSum() {
      let sum = 0
      for (const i in this.products) {
        sum += this.products[i].price
      }

      return sum * 0.01
    },
  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
