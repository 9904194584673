<template>
  <div>
    <div class="product-checkout row mt-0">

    <!-- Products List -->
      <div class="col-lg-7">
        <e-commerce-checkout-step-cart-products
            :key="componentKey"
            @cart="getCartData"
        />

        <div class="row mt-3 mb-3 pb-3 border-bottom">
          <div class="col-sm-7">
            <div class="row">
              <div class="col-sm-7">
                <b-form-input
                    v-model="code"
                    placeholder="Kod rabatowy"
                    class="mb-1"
                />
              </div>
              <div class="col-sm-5">
                <b-button
                    variant="primary"
                    block
                    @click="discountAssign()"
                    class="mb-1"
                >
                  Zastosuj kod
                </b-button>
              </div>
            </div>
          </div>
          <div class="col-sm-5 text-right">
            <span style="font-size: 20px; font-weight: bold; ">
              Łączna wartość: {{ total.toFixed(2) }}zł
            </span>
          </div>
        </div>
        <div class="text-right mb-5">
          <b-button
              variant="primary"
              @click="nextStepClick()"
          >
            Przejdź do podsumowania
          </b-button>
        </div>
      </div>

      <!-- Checkout Options -->
      <div class="checkout-options col-lg-5">
        <div class="ml-lg-3">
          <div class="bg-white mb-1 pl-2 pr-2">
            <Upsell/>
          </div>
          <reviews/>
        </div>
      </div>
    </div>
    <b-modal
        ref="upsell-modal"
        no-close-on-backdrop
        hide-footer
        size="lg"
        title="Oferta specjalna"
    >
      <Upsell />
    </b-modal>
  </div>

</template>

<script>
import {BButton, BCard, BFormCheckbox, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
import store from '@/store'
import {isUserLoggedIn} from '@/auth/utils'
import router from '@/router'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'
import Reviews from "@/views/Reviews.vue";
import Upsell from "@/views/shop/checkout/Upsell.vue";

export default {
  components: {
    BFormCheckbox, BModal,
    Upsell,
    Reviews,
    // BSV
    BButton,
    BCard,
    BFormInput,
    BFormGroup,
    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  data() {
    return {
      total: {
        type: Number,
      },
      vat: 0,
      subtotal: 0,
      products: [],
      code: '',
      saveCode: [],
      componentKey: 0,
      currency: ''
    }
  },
  computed: {
    getTotalBeforeDiscount() {
      let sum = 0
      this.products.forEach(i => {
        if (i.course.subscription === 0) {
          sum += i.course.price
        } else {
          sum += i.total;
        }
      })
      return sum.toFixed(2)
    },
  },
  mounted() {
    this.showUpsell()
  },
  created() {
    this.getCart()
  },
  methods: {
    showUpsell() {
      let modalShowed = localStorage.getItem('upsell-modal');
      console.log(modalShowed);
      if(modalShowed !== '1') {
        this.$refs['upsell-modal'].show()
        localStorage.setItem('upsell-modal', 1);
      }
    },
    pushDataLayerWhenUserOpenCartSubpage() {
      const items = this.products.map(product => (
        {
          item_name: product.course.title,
          item_id: product.course.id,
          price: product.course.price,
          item_category: product.categories[0].name,
          quantity: product.quantity,
        }))
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          currency: this.currency,
          value: this.total,
          items,
        },
      })
    },
    getCart() {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          this.total = response.data.total
          this.currency = response.data.currency
          this.vat = response.data.vat
          this.subtotal = response.data.subtotal
          this.products = response.data.items
          this.pushDataLayerWhenUserOpenCartSubpage()
        })
    },
    getCartData() {
      this.getCart()
    },
    nextStepClick() {
      this.$emit('next-step')
      this.pushDataLayerWhenUserClickPayButton()
      window.scrollTo(0, 0)
    },
    discountAssign() {
      store.dispatch('app-ecommerce/addDiscountToCart', { code: this.code })
        .then(response => {
          if (response.status === 200) {
            this.saveCode.push(this.code)
            this.code = ''
            this.getCartData()
            context.emit('cart', 'recalculate')
            this.componentKey += 1
            context.$forceUpdate()
          }
        })
    },
    pushDataLayerWhenUserClickPayButton() {
      const items = this.products.map(product => ({
        item_name: product.course.title,
        item_id: product.course.id,
        price: product.course.price,
        item_category: product.categories[0].name,
        quantity: product.quantity,
      }))
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'begin_checkout',
        coupon: this.saveCode,
        ecommerce: {
          currency: this.currency,
          value: this.total,
          items,
        },
      })
    },
  },
}
</script>
