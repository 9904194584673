<template>
  <div>
    <div class="checkout-items">
      <b-card v-if="products.length === 0">
        <b-card-body
            class="bold"
        >
          Brak produktów w koszyku. Przejdź do <b-link to="/sklep">
          sklepu
        </b-link> aby wybrać produkty.
        </b-card-body>
      </b-card>
      <div
          class="row align-items-center mb-1 pb-1 mt-3 border-bottom d-none d-sm-flex"
      >
        <div class="col-sm-3"></div>
        <div class="col-sm-5"><strong>Produkt</strong></div>
        <div class="col-sm-2 text-center"><strong>Cena</strong></div>
        <div class="col-sm-2"></div>
      </div>
      <div
          v-for="product in products"
          :key="product.id"
          class="mb-1 pb-1 border-bottom"
      >
        <div class="d-sm-none row align-items-center">
          <div class="col-3">
            <b-link :href="`/sklep/${product.course.slug}`">
              <b-img
                  :src="`${product.thumbnail}`"
                  :alt="`${product.name}-${product.id}`"
                  style="max-width: 80px;"
              />
            </b-link>
          </div>
          <div class="col-7">
            <h3>
              <b-link
                  class="text-body"
                  :href="`/sklep/${product.course.slug}`"
              >
                {{ product.course.title }}
              </b-link>
            </h3>
            <h4>
             <span v-if=" product.course.price && (product.course.price).toFixed(2) !== product.total.toFixed(2)" class="text-muted">
               <del>{{ product.course.price ? (product.course.price).toFixed(2) : '' }}</del>
             </span>
              {{ product.total.toFixed(2) }} zł
            </h4>
          </div>
          <div class="col-2">
            <b-button
                variant="primary"
                size="sm"
                @click="removeProductFromCartClick(product)"
            >
              <feather-icon icon="XIcon"/>
            </b-button>
          </div>
        </div>
        <div class="row align-items-center d-none d-sm-flex ">
          <div class="col-sm-3 text-center">
            <b-link :href="`/sklep/${product.course.slug}`">
              <b-img
                  :src="`${product.thumbnail}`"
                  :alt="`${product.name}-${product.id}`"
                  style="max-width: 120px;"
              />
            </b-link>
          </div>
          <div class="col-sm-5">
            <h3>
              <b-link
                  class="text-body"
                  :href="`/sklep/${product.course.slug}`"
              >
                {{ product.course.title }}
              </b-link>
            </h3>
          </div>
          <div class="col-sm-2 text-center">
            <h4>
             <span v-if=" product.course.price && (product.course.price).toFixed(2) !== product.total.toFixed(2)" class="text-muted">
               <del>{{ product.course.price ? (product.course.price).toFixed(2) : '' }}</del>
             </span>
              {{ product.total.toFixed(2) }} zł
            </h4>
          </div>
          <div class="col-sm-2 text-right">
            <b-button
                variant="primary"
                @click="removeProductFromCartClick(product)"
            >
              <feather-icon icon="XIcon"/>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink,
} from 'bootstrap-vue'
import { ref, watch } from '@vue/composition-api'
import { useEcommerce, useEcommerceUi } from '@/views/apps/e-commerce/useEcommerce'
import store from '@/store'
import { formatDate } from '@core/utils/filter'
import Reviews from "@/views/Reviews.vue";
import Upsell from "@/views/shop/checkout/Upsell.vue";

export default {
  components: {
    Upsell,
    Reviews,
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton,
  },
  setup(props, context) {
    const products = ref([])
    const upsells = ref([])
    const cart = ref({})

    const { toggleProductInWishlist, handleCartActionClick } = useEcommerceUi()
    const { removeProductFromCart } = useEcommerce()

    const pushDataLayerWhenUserRemoveProductFromTheCart = productId => {
      const selectedProduct = products.value.filter(item => item.id === productId)[0]
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'remove_from_cart',

        ecommerce: {
          currency: selectedProduct.currency,
          value: selectedProduct.total,
          items: [{
            currency: selectedProduct.currency,
            value: selectedProduct.total,
            item_name: selectedProduct.course.title,
            item_id: selectedProduct.id,
            price: selectedProduct.course.price,
            item_category: selectedProduct.course.category,
            item_list_name: `Kategoria: ${selectedProduct.course.category_id}`,
            item_list_id: `kategoria-${selectedProduct.course.category_id}`,
            quantity: selectedProduct.quantity,
          }],
        },
      })
    }

    const removeProductFromCartClick = product => {
      removeProductFromCart(product.id)
        .then(() => {
          const productIndex = products.value.findIndex(p => p.id === product.id)
          products.value.splice(productIndex, 1)

          store.dispatch('app-ecommerce/fetchCartItems')
          context.emit('cart', 'recalculate')
        })
      pushDataLayerWhenUserRemoveProductFromTheCart(product.id)
    }

    const addUpsellProductFromCartClick = product => {
      handleCartActionClick(product);
      setTimeout(function () {
        store.dispatch('app-ecommerce/fetchCartProducts')
            .then(response => {
              products.value = response.data.items
              upsells.value = response.data.upsells
            })
        context.emit('cart', 'recalculate')
      }, 300);
    }

    const fetchCartProducts = () => {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          products.value = response.data.items
          upsells.value = response.data.upsells
        })
    }
    fetchCartProducts()

    return {
      products,
      upsells,

      // UI
      toggleProductInWishlist,
      removeProductFromCartClick,
      addUpsellProductFromCartClick,
      handleCartActionClick,

      // Filter
      formatDate,
    }
  },
}
</script>
