<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
        class="product-checkout"
        @submit.prevent="handleSubmit(onSubmit)"
    >

    <div class="row mt-2">
      <div class="col-lg-7">
        <h2>Dane do zamówienia</h2>
        <div class="main-form mt-1">
          <div v-if="!isUserLoggedIn()" class="mb-1 border-bottom pb-1">
            <b-row>
              <b-col
                  cols="12"
                  md="6"
              >
                <validation-provider
                    #default="validationContext"
                    name="Email"
                    rules="required|min:3|email"
                >
                  <b-form-group
                      label="Email"
                      label-for="email"
                      class="mb-2"
                  >
                    <b-form-input
                        id="email"
                        v-model="addressDetails.email"
                        :state="getValidationState(validationContext)"
                        trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                  cols="12"
                  md="6"
              >
                <validation-provider
                    #default="validationContext"
                    name="Hasło"
                    rules="required|min:8"
                >
                  <b-form-group
                      label="Hasło"
                      label-for="password"
                      class="mb-2"
                  >
                    <b-form-input
                        id="password"
                        v-model="addressDetails.password"
                        type="password"
                        :state="getValidationState(validationContext)"
                        trim
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <p>
              <strong>Ej! Po co to hasło?</strong><br />
              Dostęp do wszystkich Twoich materiałów jest możliwy wyłącznie z poziomu konta użytkownika. Załóż je podając email i hasło, by czerpać 100% korzyści (i przyjemności 😉) z nauki z ChemMasterem.<br /><br />
              <strong>Masz już konto? Zaloguj się <b-link :to="{name:'login'}">tutaj</b-link></strong><br />
            </p>
          </div>
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Imię"
                  rules="required|min:3"
              >
                <b-form-group
                    label="Imię"
                    label-for="first_name"
                    class="mb-2"
                >
                  <b-form-input
                      id="first-name"
                      v-model="addressDetails.first_name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Nazwisko"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Nazwisko"
                    label-for="last_name"
                    class="mb-2"
                >
                  <b-form-input
                      id="last_name"
                      v-model="addressDetails.last_name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Adres"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Adres"
                    label-for="address"
                    class="mb-2"
                >
                  <b-form-input
                      id="addresse"
                      v-model="addressDetails.address"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Nr domu/lokalu"
                  rules="required|min:1"
              >
                <b-form-group
                    label="Nr domu/lokalu"
                    label-for="address_2"
                    class="mb-2"
                >
                  <b-form-input
                      id="address_2"
                      v-model="addressDetails.address_2"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- pincode -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Kod pocztowy"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Kod pocztowy"
                    label-for="post_code"
                    class="mb-2"
                >
                  <cleave
                      id="post_code"
                      v-model="addressDetails.post_code"
                      :state="getValidationState(validationContext)"
                      :options="options.postcode"
                      class="form-control"
                      :raw="false"
                      maxlength="6"
                      minlength="6"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- City -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Miasto"
                  rules="required|min:3"
              >
                <b-form-group
                    label="Miasto"
                    label-for="city"
                    class="mb-2"
                >
                  <b-form-input
                      id="city"
                      v-model="addressDetails.city"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- state -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Kraj"
                  rules="required|min:5"
              >
                <b-form-group
                    label="Kraj"
                    label-for="country"
                    class="mb-2"
                >
                  <b-form-input
                      id="country"
                      v-model="addressDetails.country"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
                v-if="showExamYear"
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="W którym roku zdajesz maturę?"
                  rules="required|min:4"
              >
                <b-form-group
                    label="W którym roku zdajesz maturę?"
                    label-for="country"
                    class="mb-2"
                >

                  <b-form-select
                      id="exam_year"
                      v-model="addressDetails.exam_year"
                      :state="getValidationState(validationContext)"
                      :options="examYears"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Numer telefonu"
                  :rules="isRequireNewsletter ? 'required|min:9' : 'min:9'"
              >
                <b-form-group
                    label="Numer telefonu"
                    label-for="phone_number"
                    class="mb-2"
                >
                  <b-form-input
                      id="instagram"
                      v-model="addressDetails.phone_number"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}<br />
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <p style="color: #20ad95; font-size: 16px; font-weight: bold;">
                Psst..! Chcesz złapać bezpośredni kontakt z Sonią? Otwieramy drzwi do plotek (nie tylko o chemii ;-)), planów na przyszłość i wsparcia 1:1!<br />
                Wpisz swój nick na IG tutaj:
              </p>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Nick Instagram"
                  rules="required|min:3"
              >
                <b-form-group
                    label="Nick Instagram"
                    label-for="instagram"
                    class="mb-2"
                >
                  <b-form-input
                      id="instagram"
                      v-model="addressDetails.instagram"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}<br />
                    Jeśli nie posiadasz konta na Instagramie - wpisz brak
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div class="mt-2">
          <b-form-checkbox
              id="checkbox-1"
              v-model="addressDetails.is_invoice"
              name="checkbox-1"
              :value="1"
              :unchecked-value="0"
              :disabled="!total"
          >
            Zaznacz, jeżeli chcesz otrzymać fakturę lub rachunek na osobę fizyczną.
          </b-form-checkbox>
        </div>
        <div class="invoice pt-2 mt-2 border-top" v-show="addressDetails.is_invoice">
          <b-row>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Nazwa firmy / Imię nazwisko"
                  rules=""
              >
                <b-form-group
                    label="Nazwa firmy / Imię nazwisko"
                    label-for="company_name"
                    class="mb-2"
                >
                  <b-form-input
                      id="company_name"
                      v-model="addressDetails.company_name"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="NIP"
                  rules=""
              >
                <b-form-group
                    label="NIP"
                    label-for="nip"
                    class="mb-2"
                >
                  <b-form-input
                      id="nip"
                      v-model="addressDetails.nip"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Adres"
              >
                <b-form-group
                    label="Adres"
                    label-for="company_address"
                    class="mb-2"
                >
                  <b-form-input
                      id="company_address"
                      v-model="addressDetails.company_address"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- pincode -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Kod pocztowy"
              >
                <b-form-group
                    label="Kod pocztowy"
                    label-for="company_post_code"
                    class="mb-2"
                >
                  <cleave
                      id="company_post_code"
                      v-model="addressDetails.company_post_code"
                      :state="getValidationState(validationContext)"
                      :options="options.postcode"
                      class="form-control"
                      :raw="false"
                      maxlength="6"
                      minlength="6"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- City -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Miasto"
              >
                <b-form-group
                    label="Miasto"
                    label-for="company_city"
                    class="mb-2"
                >
                  <b-form-input
                      id="company_city"
                      v-model="addressDetails.company_city"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- state -->
            <b-col
                cols="12"
                md="6"
            >
              <validation-provider
                  #default="validationContext"
                  name="Kraj"
              >
                <b-form-group
                    label="Kraj"
                    label-for="company_country"
                    class="mb-2"
                >
                  <b-form-input
                      id="company_country"
                      v-model="addressDetails.company_country"
                      :state="getValidationState(validationContext)"
                      trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </div>
        <div class="mt-2 pt-2 mb-4 border-top">
          <h2 class="mb-1">Płatność</h2>
          <p class="mb-2">
            Aby dokonać płatności zostaniesz przekierowany do bezpiecznego serwisu PayU.
          </p>
          <div class="price-details">
            <b-card-text>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title mb-1" style="font-size: 10px;">
                    Administratorem danych osobowych jest ChemMaster Sonia Radosz. Twoje dane osobowe są przetwarzane
                    zgodnie z ustawą z dnia 29 sierpnia 1997 r. o ochronie danych osobowych (Dz.U.2016.922 j.t.).
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <validation-provider
                        #default="validationContext"
                        name="regulations"
                        rules="required"
                    >
                      <b-form-checkbox
                          v-model="reg"
                          :state="getValidationState(validationContext)"
                      >
                        <small class="font-weight-bolder">Przeczytałem/am i akceptuję
                          <router-link to="/regulamin">regulamin</router-link>
                          oraz
                          <router-link to="/polityka-prywatnosci">politykę prywatności *</router-link>
                        </small>
                      </b-form-checkbox>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <validation-provider
                        #default="validationContext"
                        name="regulations2"
                        rules="required"
                    >
                      <b-form-checkbox
                          v-model="reg2"
                          :state="getValidationState(validationContext)"
                      >
                        <small class="font-weight-bolder">Oświadczam, że wyrażam zgodę na rozpoczęcie świadczenia przed
                          upływem terminu do
                          odstąpienia od umowy oraz przyjmuję do wiadomości, że z chwilą rozpoczęcia świadczenia utracę
                          prawo do odstąpienia od umowy oraz że po rozpoczęciu świadczenia nie będzie możliwości zmiany
                          kursu oraz oświadczam, że podane dane osobowe są prawdziwe *</small>
                        <br>
                        <small>Ich udostępnienie jest wymagane do prawidłowego wykonania zlecenia oraz do celów
                          rozliczeń przy płatnościach internetowych.</small>
                      </b-form-checkbox>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <validation-provider
                        #default="validationContext"
                        name="regulations4"
                        :rules="isRequireNewsletter ? 'required' : ''"
                    >
                      <b-form-checkbox
                          v-model="reg4"
                          :state="getValidationState(validationContext)"
                      >
                        <small class="font-weight-bolder">Wyrażam zgodę na przetwarzanie danych osobowych oraz
                          otrzymywanie newslettera i informacji
                          marketingowych drogą e-mailową od Administratora danych: Sonia Radosz, prowadzącą działalność
                          gospodarczą pod firmą „ChemMaster Sonia Radosz” pod adresem: ul. Wodzisławska 4,
                          44-240 Żory, NIP: 6381739715. Szczegóły dotyczące przetwarzania danych znajdziesz w
                          <router-link to="/polityka-prywatnosci">Polityce Prywatności</router-link>
                          .</small>
                      </b-form-checkbox>
                      <div class="alert alert-warning p-2 mt-1" v-show="isRequireNewsletter">
                        Przy zakupie darmowego kursu zgoda marketingowa jest wymagana.
                      </div>
                    </validation-provider>
                  </div>
                </li>
              </ul>
            </b-card-text>
          </div>
          <b-button
              variant="primary"
              type="submit"
              @click="scrollToError"
          >
            Przejdź do płatności
          </b-button>
        </div>
      </div>
      <div class="col-lg-5">
        <b-card>
          <Upsell />
        </b-card>
      </div>
    </div>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormTextarea, BLink,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import Polish from 'vee-validate/dist/locale/pl'

import vSelect from 'vue-select'
import { required, integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import { useEcommerce } from '@/views/apps/e-commerce/useEcommerce'
import Cleave from 'vue-cleave-component'
import Upsell from "@/views/shop/checkout/Upsell.vue";
import { isUserLoggedIn } from '@/auth/utils'
import useJwt from "@/auth/jwt/useJwt";
import Reviews from "@/views/Reviews.vue";
import {useRouter} from "@core/utils/utils";

export default {
  components: {
    BLink,
    Reviews,
    Upsell,
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,
  },
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
    reg: {
      type: Object,
      required: true,
    },
    reg2: {
      type: Object,
      required: true,
    },
    reg4: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      locale: 'pl',
      total: 0,
      products: [],
      examYears: ['2025', '2026', '2027', '2028', '2029'],
      options: {
        postcode: {
          delimiter: '-',
          blocks: [2, 3],
          uppercase: true,
        },
      },
    }
  },
  computed: {
    isRequireNewsletter() {
      let isRequire = false

      if(this.total === 0) {
        isRequire = true
      }

      this.products.forEach(i => {
        if (i.course.require_newsletter) {
          isRequire = true
        }
      })
      return isRequire
    },
    showExamYear() {
      let showExamYear = false
      this.products.forEach(i => {
        if (i.course.category_id !== 1) {
          showExamYear = true
        }
      })
      return showExamYear
    },
  },
  created() {
    localize(this.locale, {
      messages: Polish.messages,
      attributes: {
        email: 'E-mail',
        password: 'Hasło',
      },
    })
    this.getCart()
  },
  methods: {
    isUserLoggedIn,
    getCart() {
      store.dispatch('app-ecommerce/fetchCartProducts')
        .then(response => {
          this.total = response.data.total
          this.products = response.data.items
        })
    },
    scrollToError() {
      setTimeout(() => {
        const el = document.querySelector('.is-invalid:first-of-type')
        el.scrollIntoView()
      }, 300)
    },
  },
  setup(props, context) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
      window.scrollTo(0, 0)
    })
    const { addAddressInCart } = useEcommerce()

    const onSubmit = () => {
      let self = this
      addAddressInCart(props.addressDetails, props.reg4)
        .then(response => {
          if (!isUserLoggedIn()) {
            useJwt.setToken(response.data.accessToken)
            localStorage.setItem('userData', JSON.stringify(response.data.data))
          }
          localStorage.removeItem('cartToken')
          window.location = response.data.redirectUrl
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'begin_payment',
          })
        })
        .catch(error => {
          // if email not confirmed
          if (error.response.status === 403) {
            this.$bvToast.toast(error.response.data.message, {
              title: 'e-mail nie potwierdzony',
              solid: true,
              variant: 'danger',
            })
            this.$router.push('email/verify')
          } else if (error.response.data.message) {

            let text = error.response.data.message;
            let redirectToLogin = false;
            Object.keys(error.response.data.errors).forEach(function(key) {
              text += "\n" + error.response.data.errors[key][0];

              if (key === 'email' && error.response.data.errors[key][0] === 'The email has already been taken.') {
                redirectToLogin = true;
              }
            })

            if (redirectToLogin) {
              alert('Twój email już istnieje w naszej bazie. Zaloguj się na niego!');
              window.location.href = 'logowanie';
            } else {
              alert(text)
            }
          }
        })
    }

    return {
      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // Form Validators
      required,
      integer,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
* {
  box-sizing: border-box;
}
small {
  font-size: 10px;
}
</style>
