<template>
  <form-wizard
    ref="refFormWizard"
    color="#20ad95"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      title="Koszyk"
      icon="feather icon-shopping-cart"
    >
      <e-commerce-checkout-step-cart @next-step="formWizardNextStep" />
    </tab-content>

    <!-- address -->
    <tab-content
      title="Adres"
      icon="feather icon-home"
    >
      <e-commerce-checkout-step-address
        :address-details="checkoutDetails.address"
        @next-step="formWizardNextStep"
      />
    </tab-content>

    <!-- thank you    -->
    <tab-content
      title="Płatność"
      icon="feather icon-credit-card"
    >
      <b-card title="Płatność zakończona">
        <b-card-text>

          Dziękujemy za dokonanie płatności. Mailem otrzymasz informację na temat Twojego zamówienia.

        </b-card-text>
      </b-card>
    </tab-content>

  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { BCard, BCardText } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ECommerceCheckoutStepPayment from './checkout/ECommerceCheckoutStepPayment.vue'
import ECommerceCheckoutStepCart from './checkout/ECommerceCheckoutStepCart.vue'
import ECommerceCheckoutStepAddress from './checkout/ECommerceCheckoutStepAddress.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    BCard,
    BCardText,

    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        address: '',
        address_2: '',
        post_code: '',
        city: '',
        country: '',
        is_company: false,
        company_name: '',
        nip: '',
      },
    })

    return {
      refFormWizard,
      formWizardNextStep,

      // Dummy Details
      checkoutDetails,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
