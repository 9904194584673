<template>
  <div class="upsell mb-4" v-if="showUpsell">
    <div class="upsell-title">
      Jak serio myślisz o chemii? Bo dla nas TY + CHEMMASTER = 💙💙💙<br />
      Dlatego TYLKO TERAZ możesz złapać jeszcze:
    </div>
    <div class="d-flex align-items-center">
      <div @click="toggleUpsell(1)"
           title="Doświadczenia chemiczne"
           class="d-flex ml-auto align-items-center"
           style="width: 32px; flex-shrink: 0; cursor: pointer; margin-right: 7px;"
      >
        <feather-icon v-if="!selectedUpsells[1]" icon="CircleIcon" size="32"/>
        <feather-icon v-if="selectedUpsells[1]" icon="CheckCircleIcon" size="32"/>
      </div>
      <div class="p-2 position-relative">
        <b-img
            :src="upsell1"
            alt="Upsell 1"
            class="upsell-small"
        />
        <div class="sticker-discount">-20%</div>
      </div>
      <div class="flex-fill">
        <h5><a href="https://app.chemmaster.pl/sklep/e-book-doswiadczenia-chemiczne" target="_blank">Doświadczenia chemiczne</a></h5>
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              <span class="text-muted">
                <del>79zł</del>
              </span>
              63,20zł
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center border-top">
      <div @click="toggleUpsell(2)"
           title="Doświadczenia chemiczne + Podstawowe pojęcia"
           class="d-flex ml-auto align-items-center"
           style="width: 32px; flex-shrink: 0; cursor: pointer; margin-right: 7px;"
      >
        <feather-icon v-if="!selectedUpsells[2]" icon="CircleIcon" size="32"/>
        <feather-icon v-if="selectedUpsells[2]" icon="CheckCircleIcon" size="32"/>
      </div>
      <div class="p-2 position-relative">
        <b-img
            :src="upsell2"
            alt="Upsell 2"
            class="upsell-small"
        />
        <div class="sticker-discount">-25%</div>
      </div>
      <div class="flex-fill">
        <h5>
          <a href="https://app.chemmaster.pl/sklep/e-book-doswiadczenia-chemiczne" target="_blank">Doświadczenia chemiczne</a>
          +
          <a href="https://app.chemmaster.pl/sklep/e-book-podstawowe-pojecia-w-ujeciu-rozszerzonym" target="_blank">Podstawowe pojęcia</a>
        </h5>
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              <span class="text-muted">
                <del>168zł</del>
              </span>
              126zł
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-center border-top gap-2">
      <div @click="toggleUpsell(3)"
           title="Redox + Mechanizmy reakcji w chemii organicznej + Izomeria optyczna"
           class="d-flex ml-auto align-items-center"
           style="width: 32px; flex-shrink: 0; cursor: pointer; margin-right: 7px;"
      >
        <feather-icon v-if="!selectedUpsells[3]" icon="CircleIcon" size="32"/>
        <feather-icon v-if="selectedUpsells[3]" icon="CheckCircleIcon" size="32"/>
      </div>
      <div class="p-2 position-relative">
        <b-img
            :src="upsell3"
            alt="Upsell 3"
            class="upsell-small"
        />
        <div class="sticker-discount">-30%</div>
      </div>
      <div class="flex-fill">
        <h5>
          <a href="https://app.chemmaster.pl/sklep/e-book-reakcje-utleniania-i-redukcji-redoks" target="_blank">Redox</a>
          +
          <a href="https://app.chemmaster.pl/sklep/e-book-mechanizmy-reakcji-w-chemii-organicznej" target="_blank">Mechanizmy reakcji w chemii organicznej</a>
          +
          <a href="https://app.chemmaster.pl/sklep/e-book-izomeria-optyczna" target="_blank">Izomeria optyczna</a>
        </h5>
        <div class="item-wrapper">
          <div class="item-cost">
            <h4 class="item-price">
              <span class="text-muted">
                <del>207zł</del>
              </span>
              144,90zł
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-button
          variant="primary"
          class="mt-1  mb-3 float-right"
          @click="addUpsell()"
      >
        <feather-icon
            icon="ShoppingCartIcon"
            class="mr-50"
        />
        <span>Dodaj do koszyka</span>
      </b-button>
    </div>
  </div>
</template>
<script>
import {BButton, BImg} from "bootstrap-vue";
import store from "@/store";
import {useRouter} from "@core/utils/utils";

export default {
  name: 'Upsell',
  components: {BButton, BImg},
  data() {
    return {
      upsell1: require('./../../../assets/images/chemmaster/upsells/1.png'),
      upsell2: require('./../../../assets/images/chemmaster/upsells/2.png'),
      upsell3: require('./../../../assets/images/chemmaster/upsells/3.png'),
      showUpsell: false,
      selectedUpsells: {
        1: false,
        2: false,
        3: false,
      },
      products: []
    }
  },
  mounted() {
    this.getCart()

  },
  methods: {
    getCart() {
      store.dispatch('app-ecommerce/fetchCartProducts')
          .then(response => {
            this.products = response.data.items;
            this.checkCoursesInProducts()
          })
    },
    checkCoursesInProducts() {
      this.products.forEach(i => {
        console.log(i.course.price);
        if (i.course.price >= 100) {
          this.showUpsell = true;
        }
      });
    },
    toggleUpsell(number) {
      this.selectedUpsells[number] = !this.selectedUpsells[number];
    },
    addUpsell() {
      const token = localStorage.getItem('cartToken')
      const data = {
        upsells: this.selectedUpsells
      }

      this.$http.post(`${this.$store.state.apiDomain}/api/cart/${token}/addToCartUpsell`, data).then(response => {
        if (response.status === 200) {
          window.location.reload();
        }
      })
    }
  }
}
</script>
<style lang="scss">
.upsell-title{
  font-size: 14px;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px
}
.upsell-small {
  max-width: 150px!important;
  @media screen and (max-width: 768px) {
    max-width: 80px!important;
  }
}
.sticker-discount{
  background: red;
  color: #ffffff;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.upsell .item-price{
  text-align: left;
  color: #20ad95 !important;
  font-size: 20px;
}
.upsell .item-price del{
  font-size: 14px;
}
.upsell h5 a{
  color: #6e6b7b;
}
.upsell h5 a:hover{
  text-decoration: underline;
}
</style>
